<template>
  <el-row class="movie-list">
    <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>我的帐号</span>
          </div>
          <div class="text item">
            <el-form ref="form" :model="loginUser" label-width="80px">
              <el-form-item label="用户 ID">
                <el-input v-model="loginUser.userId" style="padding-right: 1px" readonly />
              </el-form-item>
              <el-form-item label="用户名">
                <el-input v-model="loginUser.username" style="width: 70%; padding-right: 10px" readonly />
              </el-form-item>
              <el-form-item label="显示名">
                <el-input v-model="loginUser.screenName" style="width: 70%; padding-right: 10px" readonly />
                <el-button size="mini" type="info" @click="showUpdateDialog(1)">更新</el-button>
              </el-form-item>
              <el-form-item label="邮箱">
                <el-input v-model="loginUser.email" style="width: 70%; padding-right: 10px" readonly />
                <el-button size="mini" type="info" @click="showUpdateDialog(2)">更新</el-button>
              </el-form-item>
              <el-form-item label="手机">
                <el-input v-model="loginUser.mobile" style="width: 70%; padding-right: 10px" readonly />
                <el-button size="mini" type="info" @click="showUpdateDialog(3)">更新</el-button>
              </el-form-item>
              <el-form-item label="密码">
                <el-input v-model="loginUser.mobile" style="width: 70%; padding-right: 10px" readonly />
                <el-button size="mini" type="info" @click="showUpdateDialog(4)">更新</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-row>
    </el-col>
    <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>更新我的头像</span>
          </div>
          <div class="text item">
            <el-tooltip class="item" effect="dark" content="点击更新我的头像" placement="top-end">
              <el-upload
                class="avatar-uploader"
                :action="imgOssUrl"
                :headers="imgHeaders"
                :data="imgData"
                :with-credentials="true"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
              >
                <img v-if="loginUser" :src="loginUser.avatarUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </el-tooltip>
          </div>
        </el-card>
      </el-row>
    </el-col>
    <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>我的资料</span>
          </div>
          <div class="text item">
            <el-form ref="form" :model="loginUser" label-width="80px">
              <el-form-item label="性别">
                <el-input v-model="loginUser.mobile" style="width: 70%; padding-right: 10px" readonly />
                <el-button size="mini" type="info" @click="showUpdateDialog(5)">更新</el-button>
              </el-form-item>
              <el-form-item label="签名">
                <el-input v-model="loginUser.signature" type="textarea" style="width: 70%; padding-right: 10px" readonly />
                <el-button size="mini" type="info" @click="showUpdateDialog(6)">更新</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-row>
    </el-col>

    <el-dialog
      :title="updateTitle"
      append-to-body
      :visible.sync="updateDialog"
      width="30%"
      center
    >
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px; text-align: center">
        <el-card class="box-card">
          <div class="text item">
            <el-form :model="updateForm">
              <el-form-item>
                <el-input
                  v-model="updateForm.content"
                  type="text"
                  clearable
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click.native="onUpdate"
                >更新</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-row>
    </el-dialog>
    <el-dialog
      :title="updateTitle"
      append-to-body
      :visible.sync="updateEmailDialog"
      width="30%"
      center
    >
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px; text-align: center">
        <el-card class="box-card">
          <div class="text item">
            <el-form ref="form" :model="updateEmailForm" label-width="100px">
              <el-form-item label="邮箱/手机号" label-width="100px">
                <el-input
                  v-model="updateEmailForm.principal"
                  placeholder="请输入邮箱或手机号"
                  style="width: 45%; padding-right: 10px"
                  clearable
                />
                <el-button :disabled="isBtn" @click="sendVerifyCode">{{ code }}</el-button>
              </el-form-item>
              <el-form-item label="验证码" label-width="90px">
                <el-input
                  v-model="updateEmailForm.verifyCode"
                  placeholder="请输入短信验证码"
                  style="padding-right: 1px"
                  clearable
                />
              </el-form-item>
              <el-form-item label="密码">
                <el-input
                  v-model="updateEmailForm.credential"
                  type="password"
                  placeholder="请输入密码"
                  style="padding-right: 1px"
                  clearable
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click.native="updateUserEmail"
                >更新</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-row>
    </el-dialog>
    <el-dialog
      :title="updateTitle"
      append-to-body
      :visible.sync="updatePasswordDialog"
      width="30%"
      center
    >
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px; text-align: center">
        <el-card class="box-card">
          <div class="text item">
            <el-form ref="form" :model="updatePasswordForm" label-width="100px">
              <el-form-item label="当前密码">
                <el-input
                  v-model="updatePasswordForm.credential"
                  type="password"
                  placeholder="请输入当前密码"
                  style="padding-right: 1px"
                  clearable
                />
              </el-form-item>
              <el-form-item label="新密码">
                <el-input
                  v-model="updatePasswordForm.credential"
                  type="password"
                  placeholder="请输入新密码"
                  style="padding-right: 1px"
                  clearable
                />
              </el-form-item>
              <el-form-item label="确认新密码">
                <el-input
                  v-model="updatePasswordForm.credential"
                  type="password"
                  placeholder="请确认新密码"
                  style="padding-right: 1px"
                  clearable
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click.native="updateUserPassword"
                >更新</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-row>
    </el-dialog>
  </el-row>
</template>

<script>
import { userMixin } from 'assets/js/mixin'
import { updateAvatar } from '@/api/account'
import { getAuthedUser, updateAuthedUser } from '@/utils/auth'
import { getAvatarChannelInfo } from '@/api/file'
import { updateUserProfile } from '@/api/user'

export default {
  name: 'MyProfile',
  mixins: [userMixin],
  data() {
    return {
      imgOssUrl: '',
      imgHeaders: {
        Authorization: ''
      },
      imgData: {
        channelCode: 0
      },
      coverUrl: null,
      // ****************************************************************************************************************
      loginUser: null,
      updateDialog: false,
      updateType: 1,
      updateTitle: '',
      updateForm: {
        type: 1,
        content: null
      },
      updateEmailDialog: false,
      updateEmailForm: {
        principal: null,
        verifyCode: null,
        credential: null,
        captchaCode: null,
        channel: 1,
        plat: 2
      },
      updatePasswordDialog: false,
      updatePasswordForm: {
        principal: null,
        verifyCode: null,
        credential: null,
        captchaCode: null,
        channel: 1,
        plat: 2
      }
    }
  },
  created() {
    this.loginUser = getAuthedUser()
    getAvatarChannelInfo().then(res => {
      if (res.code === 0) {
        const resData = res.data
        this.imgData.channelCode = resData.channelCode
        this.imgOssUrl = resData.ossUrl
        this.imgHeaders.Authorization = 'Bearer ' + resData.token
      } else {
        this.$notify({
          title: '提示',
          message: '获取 OSS 服务器地址失败, 暂时无法上传文件',
          type: 'error',
          duration: 3000
        })
      }
    }).catch(error => {
      this.$notify({
        title: '提示',
        message: error.message,
        type: 'warning',
        duration: 3000
      })
    })
  },
  mounted() {
  },
  methods: {
    // ****************************************************************************************************************
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('头像文件只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('头像文件大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    handleAvatarSuccess(res, file) {
      if (res.code === 0) {
        const resData = res.data
        this.coverUrl = URL.createObjectURL(file.raw)

        const avatar = {}
        avatar.channelId = this.imgData.channelCode
        avatar.uploadId = resData.uploadId
        updateAvatar(avatar).then(resp => {
          if (resp.code === 0) {
            this.loginUser.avatarUrl = resp.data.url
            updateAuthedUser(this.loginUser)
          } else {
            this.$notify({
              title: '头像更新失败',
              message: resp.msg,
              type: 'warning',
              duration: 3000
            })
          }
        })
      } else {
        this.$notify({
          title: '提示',
          message: '头像上传失败，请重试！' + res.msg,
          type: 'warning',
          duration: 3000
        })
      }
    },
    // ****************************************************************************************************************
    showUpdateDialog(type) {
      if (type === 1) {
        this.updateType = 1
        this.updateTitle = '更新用户显示名'
        this.updateDialog = true
      } else if (type === 2) {
        this.updateType = 2
        this.updateTitle = '更新用户邮箱'
        this.updateEmailDialog = true
      } else if (type === 3) {
        this.updateType = 3
        this.updateTitle = '更新用户手机号'
        this.updateEmailDialog = true
      } else if (type === 4) {
        this.updateType = 4
        this.updateTitle = '更新用户密码'
        this.updatePasswordDialog = true
      } else if (type === 5) {
        this.updateType = 5
        this.updateTitle = '更新用户性别'
        this.updateDialog = true
      } else if (type === 6) {
        this.updateType = 6
        this.updateTitle = '更新用户签名'
        this.updateDialog = true
      }
    },
    onUpdate() {
      updateUserProfile(this.updateForm).then(resp => {
        if (resp.code === 0) {
          this.updateForm.content = null
          this.$notify.info({
            message: '用户资料已更新',
            duration: 3000
          })
        } else {
          this.$notify.warning({
            message: resp.msg,
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify.error({
          message: error.message,
          duration: 3000
        })
      })

      if (this.updateType === 2 || this.updateType === 3) {
        this.updateEmailDialog = false
      } else {
        this.updateDialog = false
      }
    },
    sendVerifyCode() {
      this.$message.info('发送验证码')
    },
    updateUserEmail() {
      this.$message.info(this.updateEmailForm)
      this.updateEmailDialog = false
    },
    updateUserPassword() {
      this.$message.info(this.updatePasswordForm)
      this.updatePasswordDialog = false
    }
  }
}
</script>

<style>
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 256px;
  height: 256px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 256px;
  height: 256px;
  display: block;
}
</style>
